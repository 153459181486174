<template>
  <docs-layout>
    <div class="w-full space-y-4 mb-4 text-left">
      <p>Please refer to <asom-link href="https://tailwindui.com/components/application-ui/forms/form-layouts" target="_blank">Tailwind CSS Components</asom-link> when designing form</p>
      <h2 class="text-2xl font-bold leading-6 text-gray-900">Form Field</h2>
      <p>Form field component wraps around a form input element, and provides styling for label, description and error list of the field</p>
      <div class="w-1/2 space-y-4">
        <asom-form-field label="Field label" description="Description">
          <asom-input-text placeholder="Input field" />
        </asom-form-field>
        <asom-form-field label="Field label of required field" required description="Description">
          <asom-input-text placeholder="Input field" />
        </asom-form-field>
        <asom-form-field label="Field label with error" description="Description" error="Field is required" state="error">
          <asom-input-text placeholder="Input field" state="error" />
        </asom-form-field>
      </div>
      <p>Label and description can be customized using slot properties</p>
      <div class="w-1/2 space-y-4">
        <asom-form-field>
          <template #label>
            <label class="text-lg font-bold text-red-700">Custom label</label>
          </template>
          <template #description>
            <p class="text-sm text-green-700">Custom description</p>
          </template>
          <asom-input-text placeholder="Input field" />
        </asom-form-field>
      </div>
      <h2 class="text-2xl font-bold leading-6 text-gray-900 pt-10">Full Form Example</h2>
      <p>Below is an example of how form should look like</p>
      <div class="bg-gray-100 p-4">
        <div>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Profile</h3>
                <p class="mt-1 text-sm text-gray-600">
                  This information will be displayed publicly so be careful what you share.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <asom-card>
                <form action="#" method="POST">
                  <div class="space-y-6 sm:py-6">
                    <asom-form-field label="Website">
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          http://
                        </span>
                        <input type="text" name="company_website" id="company_website" class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300" placeholder="www.example.com">
                      </div>
                    </asom-form-field>
                    <asom-form-field
                      label="About"
                      description="Brief description for your profile. URLs are hyperlinked. "
                    >
                      <asom-input-textarea placeholder="user@example.com" />
                    </asom-form-field>
                    <asom-form-field label="Photo">
                      <div class="mt-2 flex items-center">
                        <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                          <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                        <button type="button" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-bold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Change
                        </button>
                      </div>
                    </asom-form-field>
                    <asom-form-field label="Cover photo">
                      <div class="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div class="space-y-1 text-center">
                          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <div class="flex text-sm text-gray-600">
                            <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-bold text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                              <span>Upload a file</span>
                              <input id="file-upload" name="file-upload" type="file" class="sr-only">
                            </label>
                            <p class="pl-1">or drag and drop</p>
                          </div>
                          <p class="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </asom-form-field>
                  </div>
                  <div class="flex justify-end">
                    <asom-button text="Save" />
                  </div>
                </form>
              </asom-card>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Personal Information</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <asom-card>
                <form action="#" method="POST">
                  <div class="space-y-6 sm:py-6">
                    <asom-form-field label="First name">
                      <asom-input-text />
                    </asom-form-field>
                    <asom-form-field label="Last name">
                      <asom-input-text />
                    </asom-form-field>
                    <asom-form-field label="Last name">
                      <asom-input-text id="email_address" autocomplete="email" />
                    </asom-form-field>
                    <asom-form-field label="Country / Region">
                      <select id="country" name="country" autocomplete="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select>
                    </asom-form-field>
                    <asom-form-field label="Street address">
                      <asom-input-text type="text" name="street_address" id="street_address" autocomplete="street-address" />
                    </asom-form-field>
                    <asom-form-field label="City">
                      <asom-input-text type="text" name="city" id="city" />
                    </asom-form-field>
                    <asom-form-field label="State / Province">
                      <asom-input-text type="text" name="state" id="state" />
                    </asom-form-field>
                    <asom-form-field label="ZIP / Postal">
                      <asom-input-text type="text" name="postal_code" id="postal_code" autocomplete="postal-code" />
                    </asom-form-field>
                  </div>
                  <div class="flex justify-end">
                    <asom-button text="Save" />
                  </div>
                </form>
              </asom-card>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-bold leading-6 text-gray-900">Notifications</h3>
                <p class="mt-1 text-sm text-gray-600">
                  Decide which communications you'd like to receive and how.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2">
              <asom-card>
                <form action="#" method="POST">
                  <div class="sm:py-6 space-y-6">
                    <asom-form-field>
                      <template #label>
                        <legend class="text-base font-bold text-gray-900">By Email</legend>
                      </template>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="comments" class="font-bold text-gray-700">Comments</label>
                            <p class="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                          </div>
                        </div>
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="candidates" name="candidates" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="candidates" class="font-bold text-gray-700">Candidates</label>
                            <p class="text-gray-500">Get notified when a candidate applies for a job.</p>
                          </div>
                        </div>
                        <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="offers" class="font-bold text-gray-700">Offers</label>
                            <p class="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                          </div>
                        </div>
                      </div>
                    </asom-form-field>
                    <asom-form-field>
                      <template #label>
                        <legend class="text-base font-bold text-gray-900">Push Notifications</legend>
                      </template>
                      <template #description>
                        <p class="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                      </template>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input id="push_everything" name="push_notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
                          <label for="push_everything" class="ml-3 block text-sm font-bold text-gray-700">
                            Everything
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input id="push_email" name="push_notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
                          <label for="push_email" class="ml-3 block text-sm font-bold text-gray-700">
                            Same as email
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input id="push_nothing" name="push_notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
                          <label for="push_nothing" class="ml-3 block text-sm font-bold text-gray-700">
                            No push notifications
                          </label>
                        </div>
                      </div>
                    </asom-form-field>
                  </div>
                  <div class="flex justify-end">
                    <asom-button text="Save" />
                  </div>
                </form>
              </asom-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </docs-layout>
</template>

<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout,
  },
}
</script>

<style>

</style>